<template>
    <div class="sewer-container">
        <div class="header-container">
            <ul id="project-layers" class="tocList layers-container">
                <li><geo-json-layers /></li>
                <li><treeview :zoomLevel="zoomLevel" /></li>
            </ul>
        </div>

        <div class="content-container">
            Owner:
            <select v-model="columnValue">
                <option
                    v-for="(column, index) in columnOptions"
                    :key="index"
                    :value="column"
                >
                    {{ column }}
                </option>
            </select>
        </div>

        <div class="footer-container">
            <div>
                <b-form-file
                    accept=".zip"
                    v-model="file"
                    class="upload-formlabel"
                    id="newSewerModelUpload"
                    ref="newSewerModelUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="sewerModelFileValidation()"
                ></b-form-file>
            </div>
            <div>
                <b-button
                    class="buttonSelect map-btn m-1"
                    @click="uploadSewerModel(file)"
                >
                    Upload
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import treeview from '../EmergencyManagement/TableOfContents/treeview.vue';
import GeoJsonLayers from '../EmergencyManagement/TableOfContents/GeoJsonLayers.vue';
import { dreamsService } from '../../services/dreams.service';
import { dreamsFunctions } from '../../utilities/dreamsFunctions';
import Vue from 'vue';

export default {
    name: 'RiskModel',
    components: {
        treeview,
        GeoJsonLayers,
    },
    provide: {},
    mixins: [],
    data() {
        return {
            file: null,
            zoomLevel: 5,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            columnSelected: 'Diameter',
            currentGeoJson: null,
            loading: false,
            columnValue: 'TORRANCE',
            columnOptions: [
                'ALHAMBRA',
                'ARCADIA',
                'AZUSA',
                'BELL',
                'CERRITOS',
                'CLAREMONT',
                'COMPTON',
                'COVINA',
                'CUDAHY',
                'CULVER CITY',
                'DOWNEY',
                'EL MONTE',
                'EL SEGUNDO',
                'GARDENA',
                'HAWTHORNE',
                'HERMOSA BEACH',
                'HUNTINGTON PARK',
                'IEUA',
                'INGLEWOOD',
                'L.A. COUNTY',
                'LA PUENTE',
                'LA VERNE',
                'LANCASTER - LANCASTER',
                'LOS ANGELES',
                'LYNWOOD',
                'MANHATTAN BEACH',
                'MAYWOOD',
                'MONROVIA',
                'MONTEBELLO',
                'MONTEREY PARK',
                'NEWHALL CO. WATER DIS',
                'NORWALK',
                'OTHERS',
                'POLB',
                'POMONA',
                'PRIVATE',
                'REDONDO BEACH',
                'SAN GABRIEL',
                'SAN MARINO',
                'SANTA CLARITA',
                'SIERRA MADRE',
                'SOUTH GATE',
                'SOUTH PASADENA',
                'TORRANCE',
                'VERNON',
                'WEST COVINA',
                'WHITTIER',
            ],

            diameterOptions: [
                'Less than 24 inches',
                '24 to 48',
                '48 to 56',
                'Greater than 56 inches',
            ], //TODO:implement NEW
            dataPipes: [],
            editOptions: {
                allowAdding: false,
                allowEditing: true,
                allowDeleting: false,
                mode: 'Batch',
            },
            toolbarOptions: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
            numericParams: { params: { format: 'C2' } },
            batchEdit: false,
            disableGenerateYear: true,
            intervalQueue: [null, null],
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        ...mapActions('dreams', [
            'setModel',
            'setRiskModel',
            'clearRiskAttribute',
            'setColorRange',
            'clearColorRange',
            'addRiskAttribute',
            'clearDreams',
        ]),
        sewerModelFileValidation() {
            var fileInput = document.getElementById('newSewerModelUpload');
            var filePath = fileInput.value;
            var allowedExtensions = /\.zip$/i;
            var extensionCheck = allowedExtensions.exec(filePath);
            if (!extensionCheck) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'Invalid file type',
                });
                fileInput.value = '';
                return false;
            }
        },
        uploadSewerModel(file) {
            const queueService = 'Upload sewer model';
            try {
                let data = new FormData();
                data.append('shapefile', file);
                data.append('table_name_prefix', '_');
                data.append('table_name_suffix', '_');

                dreamsService.ShapeFileToGeoJson(data).then((init_response) => {
                    setTimeout(() => {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'progress',
                            queueMessage: 'in progress!',
                            downloadLink: '',
                            visible: true,
                        });
                    }, '1000');

                    const taskId = init_response.data.task_id;
                    this.$refs['newHydraulicsModelUpload'].reset();
                    this.startStatusCheckInterval(taskId, queueService, 0); //0:uploadSewerModel
                });
            } catch (error) {
                this.$refs['newHydraulicsModelUpload'].reset();
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        hasRiskModel() {
            return dreamsFunctions.hasLineLayer(this.riskModel);
        },
        loadSewerModel() {
            const queueService = 'Load sewer model';
            try {
                dreamsService
                    .LoadSewer(this.columnValue)
                    .then((init_response) => {
                        setTimeout(() => {
                            this.setToastMessage({
                                queueService: queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                downloadLink: '',
                                visible: true,
                            });
                        }, '1000');

                        const taskId = init_response.data.task_id;
                        this.startStatusCheckInterval(taskId, queueService, 1); //1:loadSewerModel
                    });
            } catch (error) {
                this.setToastMessage({
                    queueService: queueService,
                    queueState: 'error',
                    queueMessage: error.request,
                    downloadLink: '',
                    visible: true,
                });
            }
        },
        async startStatusCheckInterval(
            taskId,
            queueService,
            queueId,
            showSuccess = true
        ) {
            this.intervalQueue[queueId] = setInterval(async () => {
                try {
                    const status_response = await dreamsService.TaskStatus(
                        taskId
                    );
                    const taskStatus = status_response.data.task_status;
                    let taskResult = status_response.data.task_result;
                    if (taskStatus == 'SUCCESS') {
                        taskResult = JSON.parse(taskResult);
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'complete',
                            queueMessage: 'completed.',
                            downloadLink: '',
                            visible: showSuccess,
                        });
                        clearInterval(this.intervalQueue[queueId]);
                    } else if (taskStatus == 'FAILURE') {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: taskResult,
                            downloadLink: '',
                            visible: true,
                        });
                        clearInterval(this.intervalQueue[queueId]);
                    }
                } catch (error) {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'error',
                        queueMessage: error,
                        downloadLink: '',
                        visible: true,
                    });
                }
            }, 5000);
        },
    }, //</methods>

    created() {
        this.setModel('Sewer');
        this.clearColorRange();
        this.loadSewerModel();
    },
    mounted() {
        if (this.hasRiskModel()) {
            this.updateColorRange();
        }
    },
    beforeDestroy() {
        this.clearColorRange();
        dreamsFunctions.clearMap();
        this.setModel('');
    },
    watch: {
        selectedLegendColor(newCheckedColor, oldCheckedColor) {
            this.updateColorRange();
        },
        ProjectId(newVal, OldValue) {
            this.clearColorRange();
            dreamsFunctions.clearMap();
            //this.clearDreams();
        },
        columnValue(newColumnValue, oldColumnValue) {
            this.loading = true;
            this.loadSewerModel();
        },
    },
    computed: {
        ...mapGetters('projectLayers', ['legendProjectLayers']),
        ...mapGetters('projects', {
            ProjectId: 'ProjectID',
        }),
        ...mapGetters('dreams', {
            version: 'version',
            riskRange: 'riskRange',
        }),
        ...mapState('dreams', {
            queueRiskAttribute: (state) => state.riskAttribute,
            riskModel: (state) => state.dreams.Risk,
            selectedLegendColor: (state) => state.showColorRange,
        }),
        UserId() {
            return this.$store.state.store.posts.UserID.toString();
        },
    },
};
</script>

<style lang="scss" scoped>
.sewer-container {
    position: relative;
    float: left;
    height: calc(100vh - 126px);
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.layers-container {
    padding: 0 27px 0 19px;
    margin-top: 5px;
}
.header-container {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100vh - 324px);
}

.content-container {
    position: relative;
    float: left;
    width: 100%;
    padding: 10px;
}
.footer-container {
    display: none;
    align-self: flex-end;
    float: right;
    width: 100%;
    min-height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    text-align: center;
    justify-content: space-between;
    padding: 30px 10px;
}
.upload-formlabel {
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    color: #626262 !important;
    height: 100%;
    line-height: 2;
    font-family: var(--ffr);
    font-size: 16px;
    font-weight: normal;
    padding-left: 18px;
    text-align: left;
}
</style>
